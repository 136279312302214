<template>
  <div class="homePublic" style="margin-top: -120px">
    <section class="hero is-medium is-main is-darkNO">
      <div class="hero-body" style="padding-bottom: 4rem">
        <br />
        <br />

        <!-- 

          Fullstack doesn't need to be so messy.

Launch modern APIs using a comprehensive toolkit.

        -->
        <p class="title is-3">
          Build your API 80% faster.
          <!--    AI + APIs = The Future of Fullstack -->
        </p>
        <p class="subtitle is-3">No boilerplate code, AI does the work, comprehensive toolkit.</p>

        <!-- 

          visual dev tools

framework made for AI
                 <p class="title is-3">AI + APIs = The Future of Fullstack</p>
        <p class="subtitle is-3">Develop modern APIs using AI</p>

          AI + APIs = The Future of Web Development</p>
        <p class="subtitle is-3">Develop modern APIs using AI tools

Don't Get Left Behind,
          Develop backends using an AI-powered toolkit.
          The Future of Fullstack Development is here
          Fullstack doesn't need to be so messy.

Handle your API with a modern, AI-powered toolkit.
        <p class="title is-3">Fullstack doesn't need to be so messy.</p>
        <p class="subtitle is-3">Build your API using modern AI-powered toolkit.</p>

          Handle your API with a modern, AI-powered toolkit.
          
          Plan, prototype and optimize  toolkit   modern toolkit   turbo-charged toolkit   progressive toolkit    extended    -->

        <waitlist style="max-width: 300px" />

        <!-- 
          <p>
          <router-link :to="{ path: '/start' }" active-class="is-active" exact>
            <b-button type="is-dark">Join the waitlist</b-button>
          </router-link>
          or
          <router-link :to="{ path: '/features' }" active-class="is-active" exact>
            explore the API toolkit
          </router-link>
        </p>
         -->
      </div>
    </section>
    <br />
    <br />

    <div class="section">
      <div class="container home">
        <p class="title is-3">
          Fullstack doesn't need to be so messy.

          <!--

  Fullstack doesn't need to be so messy.
Launch modern APIs using a comprehensive toolkit.


          Build Better, Faster with AI
          APIs + AI = 🖤

     The Future of API Development is here.
          -->
        </p>
        <p class="subtitle is-5">Build Better, Faster with AI</p>

        <div class="columns">
          <div class="column" v-for="feature in keyFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  {{ feature.description }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- eo col-->
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class=" " style="text-align: center">
          <p class="title is-2">How Midrun works</p>
          <p class="subtitle is-5">One API Dashboard to rule them all</p>
          <br />
        </div>

        <div class="columns">
          <div class="column" v-for="feature in howItWorks" :key="feature.title">
            <h3 class="title" style="margin-bottom: 6px">{{ feature.title }}</h3>
            <div class="content">
              <ul>
                <li v-for="item in feature.items" :key="item" v-html="item"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- eo how-->

    <div class="section" id="pricing">
      <div class="container home" style="text-align: center">
        <p class="title is-2">Work smarter, not harder</p>
        <p class="subtitle is-5">Cancel anytime. Host your apps anywhere.</p>
        <pricing />
      </div>
    </div>
    <!-- eo priicng-->

    <div class="section" id=" ">
      <div class="container footer" style="text-align: left">
        <p class=" ">© Midrun, {{ new Date().getFullYear() }} ⚡</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";

import pricing from "@/components/brochure/pricing.vue";

const keyFeatures = [
  {
    title: "Accelerate Full-Stack Dev",
    description: "Midrun streamlines development, keeping specs in sync and simplifying debugging.",
  },
  {
    title: "Let the AI draft it",
    description: "Generate API specs and working code for all your functions. Focus on the important stuff.",
  },
  {
    title: "Flexible Tools", //toolkit
    description: "Open-source, avoid lock-ins, scale with ease, build the solution you need.",
  },
];

const howItWorks = [
  {
    title: "Design",
    items: [
      "Draft your API using AI",
      "Make key implementation decisions",
      "Organize your endpoints",
      "Limit access by roles",
      //   "Choose key libraries and implementation strategies",

      "Generate documentation & frontend SDKs",
    ],
  },
  {
    title: "Dev",
    items: [
      "Refine AI-generated functions",
      "Minimal, expressive syntax",
      "Batteries included CLI",
      "<code>midrun dev</code> keeps everything in sync",
      "Debug using the API playground",
      "Launch with <code>midrun deploy</code>",
    ],
  },
  {
    title: "Manage",
    items: [
      "Monitor API usage",
      "Fine-tune cache durations",
      "Rate-limit sensitive calls",
      "Optimize slow functions",
      "Scale your servers",
    ],
  },
];

/*[
  {
    title: "Speed Up Full-Stack Development",
    description:
      "Midrun centralizes all key information to save you time. Our development tools streamline the process, keeping specifications in sync and simplifying debugging.",
  },
  {
    title: "Effortless API Generation",
    description:
      "Midrun's tools help you write your API specification and generate draft code for all functions with ease. Whether you're an experienced coder or just starting out, you can easily adjust the details to fit your needs.",
  },
  {
    title: "Freedom to Customize",
    description:
      "Our open-source based toolkit gives you the flexibility you need to succeed. Avoid platform and vendor lock-ins and scale with ease, without any hidden fees. Midrun gives you the freedom to build the solution that's right for you.",
  },
];
*/
/*


[
  {
    title: "AI-Optimized API Development",
    description:
      "Midrun leverages AI algorithms to optimize API development, freeing up time and improving efficiency.",
  },
  {
    title: "Predictive Coding",
    description: "Real-time code suggestions and completion to save time and reduce errors.",
  },
  {
    title: "Flexible Toolkit",
    description: "Customizable options to fit your specific needs and build the APIs you need to succeed.",
  },
];*/

export default {
  name: "Home",
  components: {
    waitlist,
    pricing,
    //  HelloWorld,
    // projCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Midrun - Build better APIs", //"ApiBros 😎",
    // all titles will be injected into this template
    // titleTemplate: "%s  🍔  ",
    //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
  data() {
    return {
      keyFeatures,
      howItWorks,
      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
  },
};
</script>
<style scoped>
.hero.is-main {
  background-color: #167df0;
  color: #fff;
  background: linear-gradient(150deg, #ffc92f, #ff185c, #f316d5, #b81ed2);

  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
}
</style>
